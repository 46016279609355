export default {
  appName: "新疆交投",
  login: "登录",
  welcome: "欢迎登录",
  userName: "用户名",
  password: "密码",
  waylinePreview: {
    lowest: "最低点",
    highest: "最高点",
  },
  menu: {
    home: "首页",
    Inspect: "巡检养护",
    Report: "巡查结果",
    Wayline: "航线管理",
    Asset: "资产管理",
    Layer: "图层管理",
  },
  Inspect: {
    Cockpit: "虚拟座舱",
    RTinspect: "即时复检",
    TaskPlan: "计划管理",
  },
  Report: {
    media: "媒体文件",
    label: "病害标注",
    view: "病害查看",
  },
  Asset: {
    Drone: "巡检设备",
    Dock: "机场管理",
    Device: "设备保养",
  },
  Wayline: {
    WayPoint: "沉浸规划",
    Bridge: "桥梁巡检",
    Routine: "常规巡检",
    Circle: "环绕建图",
    Grid: "网格建图",
    Simulation: "航线模拟",
    Approval: "适航审批",
  },
  Layer: {
    Mesh: "实景模型",
    Structure: "路桥结构",
    Vector: "矢量标注",
  },

  home: {
    Dock: "机场",
  },
  dockModeCode: {
    离线: "离线",
    空闲中: "空闲中",
    现场调试: "现场调试",
    远程调试: "远程调试",
    固件升级中: "固件升级中",
    作业中: "作业中",
  },
  modeCode: {
    待机: "待机",
    起飞准备: "起飞准备",
    起飞准备完毕: "起飞准备完毕",
    手动飞行: "手动飞行",
    自动起飞: "自动起飞",
    航线飞行: "航线飞行",
    全景拍照: "全景拍照",
    智能跟随: "智能跟随",
    "ADS-B 躲避": "ADS-B 躲避",
    自动返航: "自动返航",
    自动降落: "自动降落",
    强制降落: "强制降落",
    三桨叶降落: "三桨叶降落",
    升级中: "升级中",
    未连接: "未连接",
  },
  commonLabel: {
    Unit: "所属单位",
    Date: "时间范围",
    Creator: "养护人员",
    Approval: "批复状态",
  },
  commonStatus: {
    Offline: "离线",
    Online: "在线",
  },
  commonPlaceholder: {
    Search: "请输入要搜索的内容",
    Unit: "请选择所属公司",
    Device: "请选择设备",
    Creator: "请选择养护人员",
  },

  Cockpit: {
    table: {
      Name: "设备名称",
      Unit: "所属单位",
      Model: "设备型号",
      Statue: "连接状态",
      bound_time: "绑定时间",
      Insurance: "保险时长",
      insurance_time: "保险到期",
      Action: "操作",
      Location: "定位",
      Cockpit: "虚拟座舱",
    },
    outDate: "已过期",
    days: "天",
    Surveillance: "机场监控",
    Wide: "广角",
    Zoom: "长焦",
    Thermal: "红外",
    GetControl: "获取控制",
    ExitControl: "退出控制",
    Stop: "停止返航",
    GoHome: "返航",
    Emergency: "紧急停止",
    Gimbal_Control: "云台控制",
    Gimbal_Reset: "云台回中",
    Take_Photo: "拍照",
    Start_Record: "摄像",
    Remote: "遥控信号",
    Yaw: "云台偏航",
    Battery: "飞机电量",
    HSpeed: "水平速度",
    Altitude: "绝对高程",
    Roll: "飞机横滚",
    Wind: "空中风速",
    Satellite: "观测卫星",
    Pitch: "云台俯仰",
    Heading: "飞机偏航",
    VSpeed: "垂直速度",
    HomeDist: "返航距离",
  },
  TaskPlan: {
    Create_Plan: "新建计划",
    Date: "时间范围",
    Device: "执行设备",
    Creator: "养护人员",
    Planned: "计划",
    Actual: "实际时间",
    Status: "执行状态",
    Plan_Name: "计划名称",
    Type: "类型",
    Route_Name: "航线名称",
    Media: "媒体上传",
    Action: "操作",
    立即: "立即",
    定时: "定时",
    条件: "条件",
    待执行: "待执行",
    进行中: "进行中",
    成功: "成功",
    取消: "取消",
    失败: "失败",
    暂停: "暂停",
    待上传: "待上传",
    上传中: "上传中",
    上传成功: "上传成功",
    无媒体文件: "无媒体文件",

    selectDevice: "选择设备",
    selectRoute: "选择航线",

    Route: "执行航线",
    execDevice: "执行设备",
    返航高: "返航高",
    Finish_Action: "完成动作",
    返航: "返航",
    盘旋: "盘旋",
    着陆: "着陆",
    Cancel: "取消",
    OK: "确认",
    execDate: "执行日期",
    execTime: "执行时间",
    Storage: "开始执行存储",
    Battery: "开始执行电量",
  },
  Media: {
    All_Files: "全部文件",
    Zip: "压缩",
    Download: "下载",
    Delete: "删除",
    File_Name: "文件名称",
    Created: "创建时间",
    Count: "文件数量",
    Size: "文件大小",
    Creater: "用户名",
    Action: "操作",
    payload: "载荷",
    drone: "飞行器",
    path: "文件路径",
  },
  Annotate: {
    Media: "影像集合",
    Unit: "所属单位",
    Creator: "养护人员",
    Date: "提交时间",
    Annotation: "标注状态",
    Defect_Count: "病害数量",
    Action: "操作",
    Location: "查看",
  },

  Summary: {
    Location: "定位",
    Defect_Count: "病害数量",
    Annotation: "更新标注",
    Unit: "所属单位",
    Structure_Name: "结构名称",
    Create_Time: "创建时间",
    Update_Time: "更新时间",
    Action: "操作",
  },
  WayPoint: {
    WayPoint: "沉浸规划",
    WayPointName: "航点",
    Route: "航线名称",
    Open: "打开",
    Save: "保存",
    Cancel: "返回",
    Takeoff: "起飞高度",
    Speed: "航线航速",
    Add_Point: "Add Point",
    High_Speed: "High Speed",
    Infomation: "位姿信息",
    Longtitude: "经度",
    Latitude: "纬度",
    Altitude: "绝对高度",
    RelHeight: "作业高度",

    Add_Action: "新建动作",
    ObjectDist: "目标距离",
    PointSpeed: "航点航速",
    OK: "确认",
    Edit: "编辑",
    Delete: "删除",
    Action: "动作类型",
    Take_Photo: "单拍",
    Start_Record: "开始录像",
    Stop_Record: "停止录像",
    Heading: "机头偏航",
    Pitch: "云台俯仰",
    Hovering: "悬停等待",
    Lens: "镜头类型",
    Wait_time: "等待时间",
  },
  Briefing: {
    Briefing: "航线简报",
    Distance: "航程",
    Photo: "照片",
    Duration: "航时",
    Video: "视频",
    Points: "航点",
    Interval: "间隔",
    Preview: "预览",
    Save: "保存",
  },
  Bridge: {
    MainCable: "主缆",
    Suspension: "吊索",
    CableClamp: "索夹",
    CableTower: "主塔",
    StayCable: "拉索",
    TowerAnchorage: "塔锚",
    GirderAnchorage: "箱锚",
    Beam: "横梁",
    TowerInside: "塔内",
    Saddle: "鞍室",
    SideCable: "边索",
    Route: "航线名称",
    Upstream: "上行",
    Downstream: "下行",
    Start: "开始",
    End: "结束",
    Direction: "巡检方向",
    Zoom: "变焦",
    Speed: "巡检航速",
    Takeoff: "起飞高度",
    Outside1: "外侧俯角",
    Outside2: "外侧仰角",
    Inside1: "内侧俯角",
    Inside2: "内侧仰角",
    StartIndex: "起始编号",
    EndIndex: "结束编号",
    RelativeDist: "相对距离",
    Inside: "内侧",
    Outside: "外侧",
    Side: "巡检目标",
    Deflection: "侧视角度",
    Pitch: "巡检俯角",
    Overlap: "巡检重叠",
    Front: "前侧",
    Rear: "后侧",
    Location: "巡检索塔",
    Location1: "巡检拉索",
    Location2: "巡检锚头",
    Location3: "边索位置",
    A: "前侧",
    B: "中前",
    C: "中后",
    D: "后侧",
    TransHeight: "过渡高度",
    SaddleH: "鞍室高度",
  },
  Routine: {
    Routine: "常规巡检",
    Route: "航线名称",
    Linear: "线状巡检",
    Polygon: "面状巡检",
    Load: "选择",
    Draw: "绘制",
    Zoom: "变焦倍数",
    Resimple: "重采间隔",
    Sequence: "顺序执行",
    Reverse: "逆序执行",
    Overlap: "巡检重叠",
    Speed: "巡检航速",
    Takeoff: "起飞高度",
    RelDist: "相对距离",
    LeftSide: "左侧俯角",
    RightSide: "右侧俯角",
  },
  Circle: {
    Circle: "区域环绕",
    Route: "航线名称",
    Linear: "带状环绕",
    Grid: "区域环绕",
    Load: "选择",
    Draw: "绘制",
    Zoom: "Zoom",
    Sequence: "顺序执行",
    Reverse: "逆序执行",
    Overlap: "Overlap",
    Speed: "航线航速",
    Takeoff: "起飞高度",
    RelDist: "相对距离",
    RouteYaw: "航线偏航",
    Extension: "廊道拓宽",
  },
  Gird: {
    Route: "航线名称",
    Linear: "带状建图",
    Grid: "区域建图",
    Load: "选择",
    Draw: "绘制",
    Zoom: "变焦倍数",
    Sequence: "顺序执行",
    Reverse: "逆序执行",
    Overlap: "Overlap",
    Speed: "航线航速",
    Takeoff: "起飞高度",
    RelDist: "相对距离",
    RouteYaw: "航线偏航",
    Extension: "廊道拓宽",
    Type: "航线类型",
    Parallel: "平行航线",
    Perpendicular: "垂直航线",
  },
  Simulation: {
    Route: "航线名称",
    DateTime: "提交时间",
    Action: "操作",
    Edit: "编辑",
    Location: "定位",
    Delete: "删除",
    Download: "下载",
  },
  Device: {
    Name: "设备名称",
    Unit: "所属单位",
    Model: "设备型号",
    Status: "连接状态",
    Work_Status: "工作状态",
    Bind_Time: "绑定时间",
    Insurance: "保险时长",
    Insurance_Date: "保险到期",
    Action: "操作",
    Edit: "编辑",
    Location: "定位",
    Delete: "删除",
    outDate: "已过期",
    days: "天",
    Debugger: "调试",
    Cycle_Count: "循环次数",
    Flight_Count: "飞行次数",
  },
  Mesh: {
    VisibleStatus: "显示状态",
    Visible: "显示",
    Invisible: "不显示",
    Name: "名称",
    isVisible: "是否显示",
    Action: "操作",
    Location: "定位",
  },
  Structure: {
    Structure: "路桥结构",
    Name: "结构名称",
    Road: "主线道路",
    Milestone: "桩号位置",
    Count: "构件数量",
    Unit: "管养单位",
    VisibleStatus: "显示状态",
    Action: "操作",
    Location: "定位",
  },
  Vector: {
    Import: "导入标注",
    Name: "标注名称",
    Unit: "所属单位",
    Creator: "提交人员",
    Type: "标注类型",

    Size: "标注大小",
    Date: "修改时间",
    Visible: "标注显示",
    Action: "操作",
    Edit: "编辑",
    Location: "定位",
    Delete: "删除",
    Download: "下载",
  },
  Rain: {
    无降水: "无降水",
    小雨: "小雨",
    中雨: "中雨",
    大雨: "大雨",
  },
  detailCard: {
    WS: "风速",
    Temp: "气温",
    Storage: "存储",
    Battery: "电量",
    Home: "距离",
    Offline: "当前设备离线",
    Surveillance: "监控直播",
    Cockpit: "虚拟座舱",
    Information: "信息详情",
    DockInformation: "机场信息",
    DockTemp: "仓内温度",
    EnvironTemp: "环境温度",
    networkRate: "实时网速",
    humidity: "舱外湿度",
    voltage: "工作电压",
    current: "工作电流",
    upload: "等待上传",
    remoteDebugger: "远程调试",
    on: "开",
    off: "关",
    WindSpeed: "舱外风速",
    rainfall: "舱外降水",
    FitToFly: "适飞条件",
  },
  message: {
    1: "该设备下暂无相机",
    2: "设为已标注成功",
    3: "点击地图设置参考起飞点",
    4: "请先选择飞行器和载荷",
    5: "请选择桥梁",
    6: "未选择桥梁或该桥梁不包含该结构",
  },
};

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ERouterName } from "/@/types/index";
import CreatePlan from "/@/components/task/CreatePlan.vue";
import WaylinePanel from "/@/pages/page-web/projects/wayline.vue";
import DockPanel from "/@/pages/page-web/projects/dock.vue";
import LiveAgora from "/@/components/livestream-agora.vue";
import LiveOthers from "/@/components/livestream-others.vue";
import INSPECT_ROUTE from "./inspect.ts";
import HOME_ROUTE from "./home.ts";
import ASSETS_ROUTE from "./assets.ts";
import LAYER_ROUTE from "./layer.ts";
import WAYLINE_ROUTE from "./wayline.ts";
import REPORT_ROUTE from "./report.ts";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { CASAuth } from "/@/utils/casAuth.js";

//全局进度条的配置
NProgress.configure({
  easing: "ease", // 动画方式
  speed: 300, // 递增进度条的速度
  showSpinner: false, // 进度环显示隐藏
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 更改启动时使用的最小百分比
  parent: "body", //指定进度条的父容器
});
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  // 航线预览
  {
    path: "/route-preview",
    name: "route-preview",
    component: () => import("/@/pages/page-inspect/wayline-preview/index.vue"),
  },
  // 虚拟座舱
  {
    path: "/virtual-cockpit",
    name: "virtual-cockpit",
    component: () =>
      import("@/pages/page-inspect/inspect/virtual-cockpit/index.vue"),
  },
  {
    path: "/error-403",
    name: "error-403",
    component: () => import("@/pages/page-inspect/error/403.vue"),
  },
  // 首页
  {
    path: "/" + ERouterName.PROJECT,
    name: ERouterName.PROJECT,
    component: () => import("/@/pages/page-web/index.vue"),
  },
  // members, devices
  {
    path: "/" + ERouterName.HOME,
    name: ERouterName.HOME,
    component: () => import("/@/pages/page-web/home.vue"),
    children: [
      {
        path: "/" + ERouterName.MEMBERS,
        name: ERouterName.MEMBERS,
        component: () => import("/@/pages/page-web/projects/members.vue"),
      },
      {
        path: "/" + ERouterName.DEVICES,
        name: ERouterName.DEVICES,
        component: () => import("/@/pages/page-web/projects/devices.vue"),
      },
      {
        path: "/" + ERouterName.FIRMWARES,
        name: ERouterName.FIRMWARES,
        component: () => import("../pages/page-web/projects/Firmwares.vue"),
      },
    ],
  },
  // workspace
  {
    path: "/" + ERouterName.WORKSPACE,
    name: ERouterName.WORKSPACE,
    component: () => import("/@/pages/page-web/projects/workspace.vue"),
    redirect: "/" + ERouterName.TSA,
    children: [
      {
        path: "/" + ERouterName.TSA,
        component: () => import("/@/pages/page-web/projects/tsa.vue"),
      },
      {
        path: "/" + ERouterName.LIVESTREAM,
        name: ERouterName.LIVESTREAM,
        component: () => import("/@/pages/page-web/projects/livestream.vue"),
        children: [
          {
            path: ERouterName.LIVING,
            name: ERouterName.LIVING,
            components: {
              LiveAgora,
              LiveOthers,
            },
          },
        ],
      },
      {
        path: "/" + ERouterName.LAYER,
        name: ERouterName.LAYER,
        component: () => import("/@/pages/page-web/projects/layer.vue"),
      },
      {
        path: "/" + ERouterName.MEDIA,
        name: ERouterName.MEDIA,
        component: () => import("/@/pages/page-web/projects/media.vue"),
      },
      {
        path: "/" + ERouterName.WAYLINE,
        name: ERouterName.WAYLINE,
        component: () => import("/@/pages/page-web/projects/wayline.vue"),
      },
      {
        path: "/" + ERouterName.TASK,
        name: ERouterName.TASK,
        component: () => import("/@/pages/page-web/projects/task.vue"),
        children: [
          {
            path: ERouterName.CREATE_PLAN,
            name: ERouterName.CREATE_PLAN,
            component: CreatePlan,
            children: [
              {
                path: ERouterName.SELECT_PLAN,
                name: ERouterName.SELECT_PLAN,
                components: {
                  WaylinePanel,
                  DockPanel,
                },
              },
            ],
          },
        ],
      },
      {
        path: "/" + ERouterName.FLIGHT_AREA,
        name: ERouterName.FLIGHT_AREA,
        component: () => import("/@/pages/page-web/projects/flight-area.vue"),
      },
    ],
  },
  // pilot
  {
    path: '/' + ERouterName.PILOT,
    name: ERouterName.PILOT,
    component: () => import('/@/pages/page-pilot/pilot-index.vue'),
  },
  {
    path: "/" + ERouterName.PILOT_HOME,
    component: () => import("/@/pages/page-pilot/pilot-home.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_MEDIA,
    component: () => import("/@/pages/page-pilot/pilot-media.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_LIVESHARE,
    component: () => import("/@/pages/page-pilot/pilot-liveshare.vue"),
  },
  {
    path: "/" + ERouterName.PILOT_BIND,
    component: () => import("/@/pages/page-pilot/pilot-bind.vue"),
  },
  // 新加的路由
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/page-inspect/login/index.vue"),
  },
  ...INSPECT_ROUTE,
  ...HOME_ROUTE,
  ...ASSETS_ROUTE,
  ...LAYER_ROUTE,
  ...WAYLINE_ROUTE,
  ...REPORT_ROUTE,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/** 此处只添加路由进度条动画 */
router.beforeEach((to, from, next) => {
  // if (CASAuth(window.location.href, true)) {

  // }
  if (CASAuth(window.location.href, true)) {
    NProgress.start();
    // const role_name = localStorage.getItem("role_name") || "";
    // if (!role_name?.includes("无人机航线规划系统")) {
    //   if (to.name == "error-403") {
    //     next();
    //   }
    //   next({
    //     name: "error-403",
    //   });
    // }
    next();
  }
});
router.afterEach(() => {
  NProgress.done();
});
export default router;

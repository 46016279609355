import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/layer",
    name: "layer",
    component: () => import("@/pages/layout/index.vue"),
    // redirect: '/inspect/home',
    children: [
      {
        path: "model",
        name: "layer-model",
        component: () => import("@/pages/page-inspect/layer/model/index.vue"),
      },
      {
        path: "structure",
        name: "layer-structure",
        component: () => import("@/pages/page-inspect/layer/structure/index.vue"),
      },
      {
        path: "marking",
        name: "layer-marking",
        component: () => import("@/pages/page-inspect/layer/marking/index.vue"),
      },
      {
        path: "amap",
        name: "layer-amap",
        component: () => import("@/pages/page-inspect/layer/amap/index.vue"),
      },
    ],
  },
];

export default routes;

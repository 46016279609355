import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CreatePlan from "/@/components/task/CreatePlan.vue";
import WaylinePanel from "@/pages/page-inspect/inspect/plan/child/wayline.vue";
import DockPanel from "@/pages/page-inspect/inspect/plan/child/dock.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/inspect",
    name: "inspect",
    component: () => import("@/pages/layout/index.vue"),
    // redirect: '/inspect/home',
    children: [
      {
        path: "live",
        name: "inspect-live",
        component: () => import("@/pages/page-inspect/inspect/live/index.vue"),
      },

      {
        path: "plan",
        name: "inspect-plan",
        component: () => import("@/pages/page-inspect/inspect/plan/index.vue"),
      },
      {
        path: "virtual-cockpit",
        name: "inspect-virtual-cockpit",
        component: () =>
          import("@/pages/page-inspect/inspect/virtual-cockpit/index.vue"),
      },
      {
        path: "live-detail",
        name: "inspect-live-detail",
        component: () =>
          import("@/pages/page-inspect/inspect/live/live-detail.vue"),
      },
      {
        path: "instant-inspect",
        name: "inspect-instant-inspect",
        component: () =>
          import("@/pages/page-inspect/inspect/instant-inspect/index.vue"),
      },
      {
        path: "inspect-create-plan",
        name: "inspect-create-plan",
        component: () =>
          import("@/pages/page-inspect/inspect/plan/child/create-plan.vue"),
        children: [
          {
            path: "inspect-select-plan",
            name: "inspect-select-plan",
            components: {
              WaylinePanel,
              DockPanel,
            },
          },
        ],
      },
    ],
  },
];

export default routes;

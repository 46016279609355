import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/route-manage",
    name: "wayline",
    component: () => import("@/pages/layout/index.vue"),
    // redirect: '/inspect/home',
    children: [
      {
        path: "examine",
        name: "wayline-examine",
        component: () => import("@/pages/page-inspect/wayline/examine/index.vue"),
      },
      {
        path: "bridge-inspect",
        name: "wayline-bridge-inspect",
        component: () => import("@/pages/page-inspect/wayline/bridge-inspect/index.vue"),
      },
      {
        path: "surround",
        name: "wayline-surround",
        component: () => import("@/pages/page-inspect/wayline/surround/index.vue"),
      },
      {
        path: "grid",
        name: "wayline-grid",
        component: () => import("@/pages/page-inspect/wayline/grid/index.vue"),
      },
      {
        path: "routine-inspect",
        name: "wayline-routine-inspect",
        component: () => import("@/pages/page-inspect/wayline/routine-inspect/index.vue"),
      },
      {
        path: "create",
        name: "create",
        component: () => import("@/pages/page-inspect/wayline/create/index.vue"),
      },
      {
        path: "mock",
        name: "wayline-mock",
        component: () => import("@/pages/page-inspect/wayline/mock/index.vue"),
      },
    ],
  },
];

export default routes;

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/report",
    name: "report",
    component: () => import("@/pages/layout/index.vue"),
    // redirect: '/inspect/home',
    children: [
      {
        path: "inspect",
        name: "report-inspect",
        component: () => import("@/pages/page-inspect/report/inspect/index.vue"),
      },
      {
        path: "label",
        name: "report-label",
        component: () => import("@/pages/page-inspect/report/label/index.vue"),
      },
      {
        path: "media",
        name: "report-media",
        component: () => import("@/pages/page-inspect/report/media/index.vue"),
      },
    ],
  },
];

export default routes;

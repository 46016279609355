import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: () => import("@/pages/layout/index.vue"),
    redirect: '/home/index',
    children: [
      {
        path: "index",
        name: "home-index",
        component: () => import("@/pages/page-inspect/home/index.vue"),
      },
    ],
  },
];

export default routes;

class CoordinateConvert {
  private static readonly PI: number = Math.PI;
  private static readonly AXIS: number = 6378245.0;
  private static readonly OFFSET: number = 0.00669342162296594323;
  private static readonly X_PI: number =
    (CoordinateConvert.PI * 3000.0) / 180.0;

  // GCJ-02=>BD09 火星坐标系=>百度坐标系
  public static gcj2BD09(glat: number, glon: number): number[] {
    const x: number = glon;
    const y: number = glat;
    const z: number =
      Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * CoordinateConvert.X_PI);
    const theta: number =
      Math.atan2(y, x) + 0.000003 * Math.cos(x * CoordinateConvert.X_PI);
    const latlon: number[] = [];
    latlon[0] = z * Math.sin(theta) + 0.006;
    latlon[1] = z * Math.cos(theta) + 0.0065;
    return latlon;
  }

  // BD09=>GCJ-02 百度坐标系=>火星坐标系
  public static bd092GCJ(glat: number, glon: number): number[] {
    const x: number = glon - 0.0065;
    const y: number = glat - 0.006;
    const z: number =
      Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * CoordinateConvert.X_PI);
    const theta: number =
      Math.atan2(y, x) - 0.000003 * Math.cos(x * CoordinateConvert.X_PI);
    const latlon: number[] = [];
    latlon[0] = z * Math.sin(theta);
    latlon[1] = z * Math.cos(theta);
    return latlon;
  }

  // BD09=>WGS84 百度坐标系=>地球坐标系
  public static bd092WGS(glat: number, glon: number): number[] {
    const latlon: number[] = CoordinateConvert.bd092GCJ(glat, glon);
    return CoordinateConvert.gcj2WGS(latlon[0], latlon[1]);
  }

  // WGS84=》BD09 地球坐标系=>百度坐标系
  public static wgs2BD09(wgLat: number, wgLon: number): number[] {
    const latlon: number[] = CoordinateConvert.wgs2GCJ(wgLat, wgLon);
    return CoordinateConvert.gcj2BD09(latlon[0], latlon[1]);
  }

  // WGS84=》GCJ02 地球坐标系=>火星坐标系
  public static wgs2GCJ(wgLat: number, wgLon: number): number[] {
    return [wgLat, wgLon];
    const latlon: number[] = [];
    if (CoordinateConvert.outOfChina(wgLat, wgLon)) {
      latlon[0] = wgLat;
      latlon[1] = wgLon;
      return latlon;
    }
    const deltaD: number[] = CoordinateConvert.delta(wgLat, wgLon);
    latlon[0] = wgLat + deltaD[0];
    latlon[1] = wgLon + deltaD[1];

    return latlon;
  }

  // GCJ02=>WGS84 火星坐标系=>地球坐标系(粗略)
  public static gcj2WGS(glat: number, glon: number): number[] {
    const latlon: number[] = [];
    if (CoordinateConvert.outOfChina(glat, glon)) {
      latlon[0] = glat;
      latlon[1] = glon;
      return latlon;
    }
    const deltaD: number[] = CoordinateConvert.delta(glat, glon);
    latlon[0] = glat - deltaD[0];
    latlon[1] = glon - deltaD[1];
    return latlon;
  }

  // GCJ02=>WGS84 火星坐标系=>地球坐标系（精确）
  public static gcj2WGSExactly(gcjLat: number, gcjLon: number): number[] {
    return [gcjLat, gcjLon];
    let initDelta: number = 0.01;
    let threshold: number = 0.000000001;
    let dLat: number = initDelta,
      dLon: number = initDelta;
    let mLat: number = gcjLat - dLat,
      mLon: number = gcjLon - dLon;
    let pLat: number = gcjLat + dLat,
      pLon: number = gcjLon + dLon;
    let wgsLat: number,
      wgsLon: number,
      i: number = 0;
    while (true) {
      wgsLat = (mLat + pLat) / 2;
      wgsLon = (mLon + pLon) / 2;
      const tmp: number[] = CoordinateConvert.wgs2GCJ(wgsLat, wgsLon);
      dLat = tmp[0] - gcjLat;
      dLon = tmp[1] - gcjLon;
      if (Math.abs(dLat) < threshold && Math.abs(dLon) < threshold) break;

      if (dLat > 0) pLat = wgsLat;
      else mLat = wgsLat;
      if (dLon > 0) pLon = wgsLon;
      else mLon = wgsLon;

      if (++i > 1000) break;
    }
    const latlon: number[] = [];
    latlon[0] = wgsLat;
    latlon[1] = wgsLon;
    return latlon;
  }

  // 两点距离
  public static distance(
    latA: number,
    logA: number,
    latB: number,
    logB: number
  ): number {
    const earthR: number = 6371000;
    const x: number =
      Math.cos((latA * Math.PI) / 180) *
      Math.cos((latB * Math.PI) / 180) *
      Math.cos(((logA - logB) * Math.PI) / 180);
    const y: number =
      Math.sin((latA * Math.PI) / 180) * Math.sin((latB * Math.PI) / 180);
    let s: number = x + y;
    if (s > 1) s = 1;
    if (s < -1) s = -1;
    const alpha: number = Math.acos(s);
    const distance: number = alpha * earthR;
    return distance;
  }

  public static delta(wgLat: number, wgLon: number): number[] {
    const latlng: number[] = [];
    let dLat: number = CoordinateConvert.transformLat(
      wgLon - 105.0,
      wgLat - 35.0
    );
    let dLon: number = CoordinateConvert.transformLon(
      wgLon - 105.0,
      wgLat - 35.0
    );
    const radLat: number = (wgLat / 180.0) * CoordinateConvert.PI;
    let magic: number = Math.sin(radLat);
    magic = 1 - CoordinateConvert.OFFSET * magic * magic;
    const sqrtMagic: number = Math.sqrt(magic);
    dLat =
      (dLat * 180.0) /
      (((CoordinateConvert.AXIS * (1 - CoordinateConvert.OFFSET)) /
        (magic * sqrtMagic)) *
        CoordinateConvert.PI);
    dLon =
      (dLon * 180.0) /
      ((CoordinateConvert.AXIS / sqrtMagic) *
        Math.cos(radLat) *
        CoordinateConvert.PI);
    latlng[0] = dLat;
    latlng[1] = dLon;
    return latlng;
  }

  public static outOfChina(lat: number, lon: number): boolean {
    if (lon < 72.004 || lon > 137.8347) return true;
    if (lat < 0.8293 || lat > 55.8271) return true;
    return false;
  }

  public static transformLat(x: number, y: number): number {
    let ret: number =
      -100.0 +
      2.0 * x +
      3.0 * y +
      0.2 * y * y +
      0.1 * x * y +
      0.2 * Math.sqrt(Math.abs(x));
    ret +=
      ((20.0 * Math.sin(6.0 * x * CoordinateConvert.PI) +
        20.0 * Math.sin(2.0 * x * CoordinateConvert.PI)) *
        2.0) /
      3.0;
    ret +=
      ((20.0 * Math.sin(y * CoordinateConvert.PI) +
        40.0 * Math.sin((y / 3.0) * CoordinateConvert.PI)) *
        2.0) /
      3.0;
    ret +=
      ((160.0 * Math.sin((y / 12.0) * CoordinateConvert.PI) +
        320 * Math.sin((y * CoordinateConvert.PI) / 30.0)) *
        2.0) /
      3.0;
    return ret;
  }

  public static transformLon(x: number, y: number): number {
    let ret: number =
      300.0 +
      x +
      2.0 * y +
      0.1 * x * x +
      0.1 * x * y +
      0.1 * Math.sqrt(Math.abs(x));
    ret +=
      ((20.0 * Math.sin(6.0 * x * CoordinateConvert.PI) +
        20.0 * Math.sin(2.0 * x * CoordinateConvert.PI)) *
        2.0) /
      3.0;
    ret +=
      ((20.0 * Math.sin(x * CoordinateConvert.PI) +
        40.0 * Math.sin((x / 3.0) * CoordinateConvert.PI)) *
        2.0) /
      3.0;
    ret +=
      ((150.0 * Math.sin((x / 12.0) * CoordinateConvert.PI) +
        300.0 * Math.sin((x / 30.0) * CoordinateConvert.PI)) *
        2.0) /
      3.0;
    return ret;
  }
}

export default CoordinateConvert;

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/assets",
    name: "assets",
    component: () => import("@/pages/layout/index.vue"),
    // redirect: '/inspect/home',
    children: [
      {
        path: "drone",
        name: "assets-drone",
        component: () => import("@/pages/page-inspect/assets/drone/index.vue"),
      },
      {
        path: "dock",
        name: "assets-dock",
        component: () => import("@/pages/page-inspect/assets/dock/index.vue"),
      },
      {
        path: "dock-inspect",
        name: "assets-dock-inspect",
        component: () => import("@/pages/page-inspect/assets/dock/inspect.vue"),
      },
      {
        path: "devices",
        name: "assets-devices",
        component: () =>
          import("@/pages/page-inspect/assets/devices/index.vue"),
      },
    ],
  },
];

export default routes;

import { StoreOptions } from "vuex";
import * as Cesium from "cesium";
const createWaylineModule: StoreOptions<any> = {
  state: () => {
    return {
      takeOffPoint: {
        isSetting: false,
      },
      wayPointList: [],
      isEdit: false, //是否是编辑航线
      isHighSpeed: false,
      posAttrInfo: {
        longitude: "--",
        latitude: "--",
        height: "--",
        relativeHeight: "--",
        heading: "--",
        gimbalPitch: "--",
      },
    };
  },
  mutations: {
    SET_TAKE_OFF_POINT(state, payload) {
      state.takeOffPoint = payload;
    },
    SET_WAY_POINT_LIST(state, payload) {
      state.wayPointList = [];
      state.wayPointList = [...payload];
    },
    SET_IS_HIGH_SPEED(state, payload) {
      state.isHighSpeed = !state.isHighSpeed;
    },
    SET_POS_ATTR_INFO(state, payload) {
      state.posAttrInfo = { ...payload };
    },
  },
  actions: {},
  getters: {
    waylineInfo(state) {
      const info = {
        mileage: 0,
        pointLen: state.wayPointList.length,
        pic: 0,
        duration: 0,
        video: 0,
        pano: 0,
      };
      const wayPointList = state.wayPointList;
      for (let i = 0; i < wayPointList.length; i++) {
        const wayPoint = wayPointList[i];
        const actionGroups = wayPoint.actionGroups;
        const position = wayPoint.position;
        if (i + 1 <= wayPointList.length - 1) {
          const nextPosition = wayPointList[i + 1].position;
          const distance = Cesium.Cartesian3.distance(position, nextPosition);
          info.mileage += distance;
        }
        for (let j = 0; j < actionGroups.length; j++) {
          const actions = actionGroups[j].actions;
          for (let k = 0; k < actions.length; k++) {
            const action = actions[k];
            if (action.actuatorFunc === "takePhoto") {
              info.pic++;
            }
            if (action.actuatorFunc === "startRecord") {
              info.video++;
            }
            if (action.actuatorFunc === "panoShot") {
              info.pano++;
            }
          }
        }
      }

      return info;
    },
  },
};
export default createWaylineModule;

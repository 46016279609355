<template>
  <div class="project-wayline-wrapper height-100">
    <a-spin :spinning="loading" :delay="300" tip="downloading" size="large">
      <div style="height: 30px; font-weight: 450; color: #000"></div>
      <div :style="{ height: height + 'px' }" class="scrollbar">
        <div
          id="data"
          class="height-100 uranus-scrollbar"
          v-if="waylinesData.data.length !== 0"
          @scroll="onScroll"
        >
          <div v-for="wayline in waylinesData.data" :key="wayline.id">
            <div
              class="wayline-panel"
              style="padding-top: 5px"
              @click="selectRoute(wayline)"
            >
              <div class="title">
                <a-tooltip :title="wayline.name">
                  <div
                  
                    class="pr10 word-loop"
                    style="
                      width:250px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ wayline.name }}
                  </div>
                </a-tooltip>
                
                <!-- <div class="fz20">
                  <a-dropdown>
                    <a>
                      <EllipsisOutlined />
                    </a>
                    <template #overlay>
                      <a-menu
                        theme="dark"
                        class="more"
                        style="background: #3c3c3c"
                      >
                        <a-menu-item
                          @click="downloadWayline(wayline.id, wayline.name)"
                        >
                          <span>Download</span>
                        </a-menu-item>
                        <a-menu-item @click="showWaylineTip(wayline.id)">
                          <span>Delete</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div> -->
              </div>
              <div class="mt5">
                <span class="ml10"><UserOutlined /></span>
                <a-tooltip :title="wayline.user_name">
                  <span
                   
                    style="
                      width: 80px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    "
                  >
                    {{ wayline.user_name }}
                  </span>
                </a-tooltip>
                <span><RocketOutlined /></span>
                <span class="ml5">{{
                  DEVICE_NAME[wayline.drone_model_key]
                }}</span>
                <span class="ml10"
                  ><CameraFilled
                    style="border-top: 1px solid; padding-top: -3px"
                /></span>
                <span
                  class="ml5"
                  v-for="payload in wayline.payload_model_keys"
                  :key="payload.id"
                >
                  {{ DEVICE_NAME[payload] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <a-empty :image-style="{ height: '60px', marginTop: '60px' }" />
        </div>
        <a-modal
          v-model:visible="deleteTip"
          width="450px"
          :closable="false"
          :maskClosable="false"
          centered
          :okButtonProps="{ danger: true }"
          @ok="deleteWayline"
        >
          <p class="pt10 pl20" style="height: 50px">
            Wayline file is unrecoverable once deleted. Continue?
          </p>
          <template #title>
            <div class="flex-row flex-justify-center">
              <span>Delete</span>
            </div>
          </template>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, onUpdated, ref } from "vue";
import { message } from "ant-design-vue";
import {
  deleteWaylineFile,
  downloadWaylineFile,
  getWaylineFiles,
  importKmzFile,
} from "/@/api/wayline";
import { ELocalStorageKey, ERouterName } from "/@/types";
import {
  EllipsisOutlined,
  RocketOutlined,
  CameraFilled,
  UserOutlined,
  SelectOutlined,
} from "@ant-design/icons-vue";
import { DEVICE_NAME } from "/@/types/device";
import { useMyStore } from "/@/store";
import { WaylineFile } from "/@/types/wayline";
import { downloadFile } from "/@/utils/common";
import { IPage } from "/@/api/http/type";
import { CURRENT_CONFIG } from "/@/api/http/config";
import { load } from "@amap/amap-jsapi-loader";
import { getRoot } from "/@/root";

const loading = ref(false);
const store = useMyStore();
const pagination: IPage = {
  page: 1,
  total: -1,
  page_size: 10,
};

const waylinesData = reactive({
  data: [] as WaylineFile[],
});

const root = getRoot();
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!;
const deleteTip = ref(false);
const deleteWaylineId = ref<string>("");
const canRefresh = ref(true);
const importVisible = ref<boolean>(
  root.$router.currentRoute.value.name === ERouterName.WAYLINE
);
const height = ref();

onMounted(() => {
  const parent = document.getElementsByClassName("scrollbar").item(0)
    ?.parentNode as HTMLDivElement;
  height.value =
    document.body.clientHeight - parent.firstElementChild!.clientHeight;
  getWaylines();

  const key = setInterval(() => {
    const data = document.getElementById("data")
      ?.lastElementChild as HTMLDivElement;
    if (
      pagination.total === 0 ||
      Math.ceil(pagination.total / pagination.page_size) <= pagination.page ||
      height.value <= data?.clientHeight + data?.offsetTop
    ) {
      clearInterval(key);
      return;
    }
    pagination.page++;
    getWaylines();
  }, 1000);
});

function getWaylines() {
  if (!canRefresh.value) {
    return;
  }
  canRefresh.value = false;
  getWaylineFiles(workspaceId, {
    page: pagination.page,
    page_size: pagination.page_size,
    order_by: "update_time desc",
  })
    .then((res) => {
      if (res.code !== 0) {
        return;
      }
      waylinesData.data = [...waylinesData.data, ...res.data.list];
      pagination.total = res.data.pagination.total;
      pagination.page = res.data.pagination.page;
    })
    .finally(() => {
      canRefresh.value = true;
    });
}

function showWaylineTip(waylineId: string) {
  deleteWaylineId.value = waylineId;
  deleteTip.value = true;
}

function deleteWayline() {
  deleteWaylineFile(workspaceId, deleteWaylineId.value).then((res) => {
    if (res.code === 0) {
      message.success("Wayline file deleted");
    }
    deleteWaylineId.value = "";
    deleteTip.value = false;
    pagination.total = 0;
    pagination.page = 1;
    waylinesData.data = [];
    getWaylines();
  });
}

function downloadWayline(waylineId: string, fileName: string) {
  loading.value = true;
  downloadWaylineFile(workspaceId, waylineId)
    .then((res) => {
      if (!res) {
        return;
      }
      const data = new Blob([res], { type: "application/zip" });
      downloadFile(data, fileName + ".kmz");
    })
    .finally(() => {
      loading.value = false;
    });
}

function selectRoute(wayline: WaylineFile) {
  store.commit("SET_SELECT_WAYLINE_INFO", wayline);
}

function onScroll(e: any) {
  const element = e.srcElement;
  if (
    element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
    Math.ceil(pagination.total / pagination.page_size) > pagination.page &&
    canRefresh.value
  ) {
    pagination.page++;
    getWaylines();
  }
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
const fileList = ref<FileItem[]>([]);

function beforeUpload(file: FileItem) {
  fileList.value = [file];
  loading.value = true;
  return true;
}
const uploadFile = async () => {
  fileList.value.forEach(async (file: FileItem) => {
    const fileData = new FormData();
    fileData.append("file", file, file.name);
    await importKmzFile(workspaceId, fileData)
      .then((res) => {
        if (res.code === 0) {
          message.success(`${file.name} file uploaded successfully`);
          canRefresh.value = true;
          pagination.total = 0;
          pagination.page = 1;
          waylinesData.data = [];
          getWaylines();
        }
      })
      .finally(() => {
        loading.value = false;
        fileList.value = [];
      });
  });
};
</script>

<style lang="scss" scoped>
.wayline-panel {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  padding-bottom: 10px;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  color: #000;
  border: 1px solid $text-color;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}
.uranus-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c5c8cc transparent;
}
.word-loop {
  white-space: nowrap;
  display: inline-block;
  animation: 10s loop linear infinite normal;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.35;
  }
  to {
    opacity: 1;
  }
}
@keyframes loop {
  0% {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
</style>

export default {
  appName: "Frankfurt",
  login: "Login",
  welcome: "Welcome",
  userName: "UserName",
  password: "Password",
  waylinePreview:{
    lowest: "The Lowest Point",
    highest: "The Highest Point",
  },
  menu: {
    home: "Home",
    Inspect: "Inspect",
    Report: "Report",
    Wayline: "Wayline",
    Asset: "Asset",
    Layer: "Layer",
  },
  Inspect: {
    Cockpit: "Cockpit",
    RTinspect: "RTinspect",
    TaskPlan: "TaskPlan",
  },
  Report: {
    media: "Media",
    label: "Annotation",
    view: "Summary",
  },
  Asset: {
    Drone: "Drone",
    Dock: "Dock",
    Device: "Device",
  },
  Wayline: {
    WayPoint: "FreePlanning",
    Bridge: "Bridge",
    Routine: "Routine",
    Circle: "Circle",
    Grid: "Grid",
    Simulation: "Simulation",
    Approval: "Authorization",
    Authorizer: "Authorizer",
  },
  Layer: {
    Mesh: "Mesh",
    Structure: "Structure",
    Vector: "Vector",
  },
  home: {
    Dock: "Dock",
  },
  dockModeCode: {
    离线: "Disconnected",
    空闲中: "Idle",
    现场调试: "Debugging",
    远程调试: "Remote_Debugging",
    固件升级中: "Upgrading",
    作业中: "Working",
  },
  modeCode: {
    待机: "Standby",
    起飞准备: "Preparing",
    起飞准备完毕: "Ready",
    手动飞行: "Manual",
    自动起飞: "Automatic",
    航线飞行: "Waypoint",
    全景拍照: "Panoramic",
    智能跟随: "Active_Track",
    "ADS-B 躲避": "ADS_B",
    自动返航: "Return_To_Home",
    自动降落: "Landing",
    强制降落: "Forced_Landing",
    三桨叶降落: "Three_Blades_Landing",
    升级中: "Upgrading",
    未连接: "Disconnected",
  },
  commonLabel: {
    Unit: "Unit",
    Date: "Date",
    Creator: "Creator",
    Approval: "Authorization",
  },
  commonPlaceholder: {
    Search: "Search",
    Unit: "Please select unit",
    Device: "Select Device",
    Creator: "Select Creator",
    Planned: "Planned",
    Actual: "Actual",
    Status: "Status",
    Plan_Name: "Plan Name",
    Type: "Type",
    Route_Name: "Route Name",
    Media: "Media",
    Action: "Action",
  },
  commonStatus: {
    Offline: "Offline",
    Online: "Online",
  },
  Cockpit: {
    table: {
      Name: "Name",
      Unit: "Unit",
      Model: "Model",
      Statue: "Statue",
      bound_time: "Bind Time",
      Insurance: "Insurance",
      insurance_time: "Insurance Date",
      Action: "Action",
      Location: "Locate",
      Cockpit: "Cockpit",
    },
    outDate: "Out date",
    days: "days",
    Surveillance: "Dock Surveillance",
    Wide: "Wide",
    Zoom: "Zoom",
    Thermal: "Thermal",
    GetControl: "GetControl",
    ExitControl: "ExitControl",
    Stop: "Stop",
    GoHome: "GoHome",
    Emergency: "Emergency",
    Gimbal_Control: "Gimbal Control",
    Gimbal_Reset: "Gimbal Reset",
    Take_Photo: "Take Photo",
    Start_Record: "Start Record",
    Remote: "Remote",
    Yaw: "Yaw",
    Battery: "Battery",
    HSpeed: "HSpeed",
    Altitude: "Altitude",
    Roll: "Roll",
    Wind: "Wind",
    Satellite: "Satellite",
    Pitch: "Pitch",
    Heading: "Heading",
    VSpeed: "VSpeed",
    HomeDist: "HomeDist",
  },
  TaskPlan: {
    Create_Plan: "Create Plan",
    Date: "Date",
    Device: "Device",
    Creator: "Creator",
    Planned: "Planned",
    Actual: "Actual",
    Status: "Status",
    Plan_Name: "Plan Name",
    Type: "Type",
    Route_Name: "Route Name",
    Media: "Media",
    Action: "Action",
    立即: "Immediate",
    定时: "Timed",
    条件: "Recurring",
    待执行: "Wait",
    进行中: "Carrying",
    成功: "Success",
    取消: "CanCel",
    失败: "Fail",
    暂停: "Paused",
    待上传: "ToUpload",
    上传中: "Uploading",
    上传成功: "Success",
    无媒体文件: "Empty",
    selectDevice: "Select",
    selectRoute: "Select",
    Route: "Route",
    execDevice: "Device",
    返航高: "RTH Altitude (Relative to dock)",
    Finish_Action: "Finish Action",
    返航: "GoHome",
    盘旋: "Hover",
    着陆: "Land",
    Cancel: "Cancel",
    OK: "OK",
    execDate: "Date",
    execTime: "Time",
    Storage: "Storage",
    Battery: "Battery",
  },
  Media: {
    All_Files: "All Files",
    Zip: "Zip",
    Download: "Download",
    Delete: "Delete",
    File_Name: "File Name",
    Created: "Created",
    Count: "Count",
    Size: "Size",
    Creater: "Creater",
    Action: "Action",
    payload: "Payload",
    drone: "Drone",
    path: "Path",
  },
  Annotate: {
    Media: "Media",
    Unit: "Unit",
    Creator: "Creator",
    Date: "Date",
    Annotation: "Annotation",
    Defect_Count: "Defect Count",
    Action: "Action",
    Location: "Annotate",
  },
  Summary: {
    Location: "Locate",
    Defect_Count: "Defect Count",
    Annotation: "Annotation",
    Unit: "Unit",
    Structure_Name: "Structure Name",
    Create_Time: "Create Time",
    Update_Time: "Update Time",
    Action: "Action",
  },
  WayPoint: {
    WayPoint: "FreePlanning",
    WayPointName: "WayPoint",

    Route: "Route",
    Open: "Open",
    Save: "Save",
    Cancel: "Cancel",
    Takeoff: "Takeoff",
    Speed: "Speed",
    Add_Point: "Add Point",
    High_Speed: "High Speed",
    Infomation: "Infomation",
    Longtitude: "Longtitude",
    Latitude: "Latitude",
    Altitude: "Altitude",
    RelHeight: "Height",

    Add_Action: "Add Action",
    ObjectDist: "ObjectDist",
    PointSpeed: "PointSpeed",
    OK: "OK",
    Edit: "Edit",
    Delete: "Delete",
    Action: "Action",
    Take_Photo: "Take Photo",
    Start_Record: "Start Record",
    Stop_Record: "Stop Record",

    Heading: "Heading",
    Pitch: "Pitch",
    Hovering: "Hovering",
    Lens: "Lens",
    Wait_time: "Wait time",
  },
  Briefing: {
    Briefing: "Briefing",
    Distance: "Distance",
    Photo: "Photo",
    Duration: "Duration",
    Video: "Video",
    Points: "Points",
    Interval: "Interval",
    Preview: "Preview",
    Save: "OK",
  },
  Bridge: {
    MainCable: "MainCable",
    Suspension: "Suspension",
    CableClamp: "CableClamp",
    CableTower: "CableTower",
    StayCable: "StayCable",
    TowerAnchorage: "TowerAnchorage",
    GirderAnchorage: "GirderAnchorage",
    Beam: "Beam",
    TowerInside: "TowerInside",
    Saddle: "Saddle",
    SideCable: "SideCable",
    Route: "Route",
    Upstream: "Upstream",
    Downstream: "Downstream",
    Start: "Start",
    End: "End",
    Direction: "Direction",
    Zoom: "Zoom",
    Speed: "Speed",
    Takeoff: "Takeoff",
    Outside1: "Outside 1",
    Outside2: "Outside 2",
    Inside1: "Inside 1",
    Inside2: "Inside 2",
    StartIndex: "StartIndex",
    EndIndex: "EndIndex",
    RelativeDist: "RelDist",
    Inside: "Inside",
    Outside: "Outside",
    Side: "Side",
    Deflection: "Deflection",
    Pitch: "Pitch",
    Overlap: "Overlap",
    Front: "Front",
    Rear: "Rear",
    Location: "Location",
    A: "A",
    B: "B",
    C: "C",
    D: "D",
    Location1: "Location",
    Location2: "Location",
    TransHeight: "TransHeight",

    SaddleH: "SaddleH",
    Location3: "Location",
  },
  Routine: {
    Routine: "Routine",
    Route: "Route",
    Linear: "Linear",
    Polygon: "Polygon",
    Load: "Load",
    Draw: "Draw",
    Zoom: "Zoom",
    Resimple: "Resimple",
    Sequence: "Sequence",
    Reverse: "Reverse",
    Overlap: "Overlap",
    Speed: "Speed",
    Takeoff: "Takeoff",
    RelDist: "RelDist",
    LeftSide: "LeftSide",
    RightSide: "RightSide",
  },
  Circle: {
    Circle: "Circle",
    Route: "Route",
    Linear: "Linear",
    Grid: "Grid",
    Load: "Load",
    Draw: "Draw",
    Zoom: "Zoom",
    Sequence: "Sequence",
    Reverse: "Reverse",
    Overlap: "Overlap",
    Speed: "Speed",
    Takeoff: "Takeoff",
    RelDist: "RelDist",
    RouteYaw: "RouteYaw",
    Extension: "Extension",
  },
  Gird: {
    Route: "Route",
    Linear: "Linear",
    Grid: "Grid",
    Load: "Load",
    Draw: "Draw",
    Zoom: "Zoom",
    Sequence: "Sequence",
    Reverse: "Reverse",
    Overlap: "Overlap",
    Speed: "Speed",
    Takeoff: "Takeoff",
    RelDist: "RelDist",
    RouteYaw: "RouteYaw",
    Extension: "Extension",
    Type: "Type",
    Parallel: "Parallel",
    Perpendicular: "Perpendicular",
  },
  Simulation: {
    Route: "Route",
    DateTime: "DateTime",
    Action: "Action",
    Edit: "Edit",
    Location: "Locate",
    Delete: "Delete",
    Download: "Download",
  },
  Device: {
    Name: "Name",
    Unit: "Unit",
    Model: "Model",
    Status: "Status",
    Bind_Time: "Bind Time",
    Insurance: "Insurance",
    Insurance_Date: "Insurance Date",
    Action: "Action",
    Edit: "Edit",
    Location: "Locate",
    Delete: "Delete",
    outDate: "Out date",
    days: "days",
    Debugger: "Debug",
    Cycle_Count: "Cycle Count",
    Flight_Count: "Flight Count",
    Work_Status: "Work Status",
  },
  Mesh: {
    VisibleStatus: "Visible",
    Visible: "Visible",
    Invisible: "Invisible",
    Name: "Name",
    isVisible: "Visible",
    Action: "Action",
    Location: "Locate",
  },
  Structure: {
    Structure: "Structure",
    Name: "Name",
    Road: "Road",
    Milestone: "Milestone",
    Count: "Count",
    Unit: "Unit",
    VisibleStatus: "Visible",
    Action: "Action",
    Location: "Locate",
  },
  Vector: {
    Import: "Import",
    Name: "Name",
    Unit: "Unit",
    Creator: "Creator",
    Type: "Type",

    Size: "Size",
    Date: "Date",
    Visible: "Visible",
    Action: "Action",
    Edit: "Edit",
    Location: "Locate",
    Delete: "Delete",
    Download: "Download",
  },
  Rain: {
    无降水: "NoRain",
    小雨: "LightRain",
    中雨: "ModerateRain",
    大雨: "HeavyRain",
  },
  detailCard: {
    WS: "WS",
    Temp: "Temp",
    Storage: "Storage",
    Battery: "Battery",
    Home: "Home",
    Offline: "Drone Offline",
    Surveillance: "Surveillance",
    Information: "Information",
    Cockpit: "Cockpit",
    DockInformation: "Dock Status",
    DockTemp: "DockTemp",
    EnvironTemp: "EnvironTemp",
    networkRate: "Network",
    humidity: "Humidity",
    voltage: "Voltage",
    current: "Current",
    upload: "Upload",
    remoteDebugger: "Remote Debug",
    on: "on",
    off: "off",
    WindSpeed: "WindSpeed",
    rainfall: "RainFall",
    FitToFly: "FitToFly",
  },
  message: {
    1: "No Payload included",
    2: "Set image annotation status as examined and free of defects",
    3: "Click on map to set reference takeoff point ",
    4: "Select Drone",
    5: "Select Structure",
    6: "Select Structure",
  },
};

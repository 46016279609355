/**
 * 下载文件
 * @param data
 * @param fileName
 */
export function downloadFile (data: Blob, fileName: string) {
  const lable = document.createElement('a')
  lable.href = window.URL.createObjectURL(data)
  lable.download = fileName
  lable.click()
  URL.revokeObjectURL(lable.href)
}
export const keepTwoDecimalStr = (num: number) => {
  const result = Number(num.toString().match(/^\d+(?:\.\d{0,2})?/));
  let s = result.toString();
  let rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};

export function formatSeconds(seconds: any) {
  let hour =
    Math.floor(seconds / 3600) >= 10
      ? Math.floor(seconds / 3600)
      : "0" + Math.floor(seconds / 3600);
  seconds -= 3600 * hour;
  let min =
    Math.floor(seconds / 60) >= 10
      ? Math.floor(seconds / 60)
      : "0" + Math.floor(seconds / 60);
  seconds -= 60 * min;
  let sec = seconds >= 10 ? seconds : "0" + seconds;
  return min + ":" + sec;
}

import { createI18n } from "vue-i18n";
import zhCn from "./lang/zh-cn";
import en from "./lang/en";
export const messages = {
  zhCn,
  en,
};

const name: any = import.meta.env.MODE;
const langMapping: any = {
  Frankfurt: "en",
  XinJiang: "zhCn",
};
if (!localStorage.getItem("locale")) {
  localStorage.setItem("locale", langMapping[name] || "en");
  localStorage.setItem("lang", langMapping[name] || "en");
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem("locale") || langMapping[name] || "en",
  fallbackLocale: "zhCn",
  messages,
});

export default i18n;

import { ELocalStorageKey } from "/@/types/enums";
import { CURRENT_CONFIG } from "/@/api/http/config";
import CommonUserInfo from "@/common/userInfo";
export function getWebsocketUrl() {
  // const url =
  //   CURRENT_CONFIG.websocketURL +
  //   `?id=${CommonUserInfo.userId}&username=${CommonUserInfo.userName}&userType=1&workspaceId=${CommonUserInfo.workspaceId}`;
  const token: string = localStorage.getItem(ELocalStorageKey.Token) || '' as string
  const url = CURRENT_CONFIG.websocketURL + '?x-auth-token=' + encodeURI(token)
  return url;
}

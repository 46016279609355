import config from "./config.js"; // 导入配置文件

function getUrlParams(url, slash = false) {
  let service_urlStr = url.split("?");
  let currentURL = service_urlStr[0].split("/");
  let obj = {
    service: currentURL[0] + "//" + currentURL[2] + (slash ? "/" : ""),
  };
  let urlStr = service_urlStr[1];
  if (!urlStr) {
    return obj;
  }
  let paramsArr = urlStr.split("&");
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    let arr = paramsArr[i].split("=");
    obj[arr[0]] = arr[1];
  }
  return obj;
}

export function CASAuth(url, service_slash = false) {
  return true;

  let UrlPara = getUrlParams(url, service_slash);
  const path = window.location.pathname;
  const isPilot = path.includes("pilot");
  // 判断是否是遥控器端;
  if (window.localStorage.getItem("id") !== null) {
    if (UrlPara.id) {
      window.localStorage.setItem("id", UrlPara.id);
    }
    return true;
  }
  let app_url = isPilot ? config.pilot_url : config.front_url;

  let second_valid_url = `${config.backend_cas_valid_url}?service=${app_url}`;
  if (!Object.prototype.hasOwnProperty.call(UrlPara, "ticket")) {
    window.location.href = `${config.cas_server}?service=${second_valid_url}`;
    return false;
  }
  if (Object.prototype.hasOwnProperty.call(UrlPara, "ticket")) {
    window.localStorage.setItem("authDate", new Date());
    window.localStorage.setItem("id", UrlPara.id);
    window.location.href = window.location.href.split("?")[0];
    return true;
  }
}

export function RECASAuth(url, service_slash = false) {
  // 接口调用时如果检测到认证过期或者认证跳转时候调用
  let UrlPara = getUrlParams(url, service_slash);
  window.localStorage.removeItem("authDate");
  window.localStorage.removeItem("id");
  let app_url = config.front_url;
  let second_valid_url = `${config.backend_cas_valid_url}?service=${app_url}`;
  if (!Object.prototype.hasOwnProperty.call(UrlPara, "ticket")) {
    window.location.href = `${config.cas_server}?service=${second_valid_url}`;
  }
}

export default CASAuth;
